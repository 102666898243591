<template>
  <skeleton-loading>
    <div
      class='view-home__part7 instructor-info d-block pl-0 pr-0 instructor-size'
    >
      <div>
        <div class='col-12 px-0'>
          <div class='img-cover-instructor'>
            <img
              class='w-100 instructor-info__front-page'
              src='@/assets/images/logo/placeholder.png'
              style='background: #f7f7f7'
            />
          </div>
          <div class='instructor-info__info-front-page pb-6'>
            <div class='skeleton-instructor-text'>
              <skeleton-square
                :box-properties="{
                height: '50px',
                width:'500px',
                marginBottom: '15px'
                }"
                :count='1'
              />
            </div>
            <div
              class='instructor-info__info-front-page__info-social-medial d-flex mt-4'
            >
              <div class='mr-2'>
                <skeleton-circle
                  :box-properties="{
                height: '25px',
                width:'25px'
                }"
                  :count='1'
                />
              </div>
              <div class='mr-2'>
                <skeleton-circle
                  :box-properties="{
                height: '25px',
                width:'25px'
                }"
                  :count='1'
                />
              </div>
              <div class='mr-2'>
                <skeleton-circle
                  :box-properties="{
                height: '25px',
                width:'25px'
                }"
                  :count='1'
                />
              </div>
              <div>
                <skeleton-circle
                  :box-properties="{
                height: '25px',
                width:'25px'
                }"
                  :count='1'
                />
              </div>
            </div>
          </div>
          <div>
            <img
              alt=''
              class='instructor-info__img-instructor'
              src='@/assets/images/logo/profile_default.png'
              style='background: #f7f7f7'
            />
          </div>
        </div>
        <div class='container-fluid size'>
          <div class='row justify-content-end m-0'>
            <div class='col-12 instructor-info__description'>
              <skeleton-square
                :box-properties="{
                height: '20px',
                width:'100%',
                bottom: '20px'
                }"
                :count='5'
              />
            </div>
          </div>
        </div>
        <div class='container-fluid size '>
          <div class='skeleton-instructor-text'>
            <skeleton-square
              :box-properties="{
                height: '40px',
                width:'400px',
                textAlign:'-webkit-center',
                }"
              :count='1'
            />
          </div>

          <div class='row mt-4'>
            <skeleton-card-video
              v-for='index in 4'
              :key='index'
              class='col-sm-3'
            />
          </div>
        </div>
        <div class='container-fluid size mt-4'>
          <div class='skeleton-instructor-text'>
            <skeleton-square
              :box-properties="{
                height: '40px',
                width:'400px',
                textAlign:'-webkit-center',
                }"
              :count='1'
            />
          </div>

          <div class='row mt-4'>
            <skeleton-card-video
              v-for='index in 4'
              :key='index'
              class='col-sm-3'
            />
          </div>
        </div>
      </div>
    </div>
  </skeleton-loading>
</template>

<script>
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonCircle from '../../../components/Skeleton/components/SkeletonCircle';
import SkeletonCardVideo from './SkeletonCardVideo';
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';

export default {
  name: 'SkeletonInstructorDetails',
  components: {
    SkeletonSquare,
    SkeletonCircle,
    SkeletonCardVideo,
    SkeletonLoading,
  },
};
</script>

<style>
.skeleton-instructor-text {
  text-align: -webkit-center;
}
</style>
