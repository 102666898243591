<template>
  <skeleton-course-details v-if="isLoading" />
  <div v-else class="instructor-info d-block pl-0 pr-0 instructor-size">
    <div class="row">
      <div class="col-12 px-0">
        <div>
          <img
            alt=""
            class="w-100 img-course-background-image"
            :src="
              getPublicCurrentCourse.poster
                ? getPublicCurrentCourse.poster
                : require('@/assets/images/logo/placeholder.png')
            "
            style="background: #f7f7f7"
          />
        </div>
        <div class="img-course-cover-profile"></div>
      </div>
    </div>
    <div class="container-fluid size pr-1 pl-1">
      <h1 class="my-3 text-description-course">
        {{ getPublicCurrentCourse.name }}
      </h1>
      <div class="d-flex justify-content-center orientation-btn-mobile">
        <div v-if="isAuthenticated">
          <template v-if="getPublicCurrentCourse.free">
            <button
              class="btn rounded-0 btn-maquillate btn-descript-course"
              style="padding: 11px 25px"
              @click="takeCourse"
            >
              Entrar al curso
            </button>
          </template>
          <template v-else>
            <template v-if="isLoadingCourseUser">
              <skeleton-square
                :box-properties="{
                  height: '45px',
                  width: '200px',
                }"
                :count="1"
              />
            </template>
            <template v-else>
              <template v-if="getCurrentCourseUser.id">
                <button
                  class="btn rounded-0 btn-maquillate btn-descript-course"
                  style="padding: 11px 25px"
                  @click="takeCourse"
                >
                  Entrar al curso
                </button>
              </template>
              <template v-else>
                <button
                  class="btn rounded-0 btn-maquillate btn-descript-course"
                  style="padding: 11px 25px"
                  @click="buyCourseWithOutLogin"
                >
                  Desbloquear este curso por ${{ getPublicCurrentCourse.price.toFixed(2) }} USD
                </button>
              </template>
            </template>
          </template>
        </div>
        <div v-else>
          <div
            v-if="getPublicCurrentCourse.free"
            class="d-flex justify-content-center orientation-btn-mobile"
          >
            <button
              class="btn rounded-0 btn-maquillate btn-descript-course"
              style="padding: 11px 25px"
              @click="takeCourseWithOutLogin"
            >
              Tomar curso
            </button>
          </div>
          <div
            v-else
            class="d-flex justify-content-center orientation-btn-mobile"
          >
            <button
              class="btn rounded-0 btn-maquillate btn-descript-course"
              style="padding: 11px 25px"
              @click="buyCourseWithOutLogin"
            >
              Desbloquear este curso por ${{ getPublicCurrentCourse.price.toFixed(2) }} USD
            </button>
          </div>
        </div>
      </div>
      <div class="my-5 tabs-description-course style-config px-2">
        <div class="row justify-content-between mr-0 margin-mobile-container">
          <div class="col-md-5 col-12">
            <h1 class="mt-3 text-left">DESCRIPCIÓN</h1>
            <p
              class="margin-top-description text-left"
              style="color: black"
              v-html="getPublicCurrentCourse.content"
            ></p>
          </div>
          <div class="col-md-6 pr-0 pl-0 embed-container">
            <video-player
              ref="previewplayer"
              :options="videoOptions"
              class="videoplayer mt-4"
              :video-id="videoDesktopId"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 container-skills">
      <div class="container-fluid size container-learning">
        <h1 class="mb-4">¿Qué aprenderás en este curso?</h1>
        <div class="row p-lg-4">
          <div
            v-for="{ id, bullet } in getPublicCurrentCourse.bullets"
            :key="id"
            class="col-md-6"
          >
            <div class="d-flex">
              <i class="fas fa-check pr-2 pt-2"></i>
              <p class="mb-2">{{ bullet }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 padding-mobile-container mt-0 px-0">
      <div class="container-fluid size d-flex py-4">
        <div class="col-md-6 border-right-desk">
          <div class="d-flex container-info-instructor-course-descript">
            <img
              v-if="
                getPublicCurrentCourse.instructors &&
                getPublicCurrentCourse.instructors.length > 0
              "
              :src="getPublicCurrentCourse.instructors[0].instructor.avatar.url"
              alt="instructor"
              class="img-instructor-description"
            />
            <div class="d-flex flex-column justify-content-left">
              <h3>PERFIL DEL INSTRUCTOR</h3>
              <h1
                v-if="
                  getPublicCurrentCourse.instructors &&
                  getPublicCurrentCourse.instructors.length > 0
                "
                class="mb-0 text-left"
              >
                {{
                  `${getPublicCurrentCourse.instructors[0].instructor.firstname} ${getPublicCurrentCourse.instructors[0].instructor.lastname}`
                }}
              </h1>
            </div>
          </div>
          <div :class="['mt-4 see-more', isError ? 'see-less' : 'see-more']">
            <p
              v-if="
                getPublicCurrentCourse.instructors &&
                getPublicCurrentCourse.instructors.length > 0
              "
              class="pb-4 text-left mb-4"
              style="color: black; white-space: break-spaces"
            >
              {{ getPublicCurrentCourse.instructors[0].instructor.bio }}
            </p>
          </div>
          <div
            :class="['details-see-more', isError ? 'details-see-less' : ' ']"
            @click="isError = !isError"
          >
            <i
              v-if="!isError"
              class="fas fa-chevron-down"
              style="color: #b7227e; font-size: 28px"
            ></i>
            <i
              v-else
              class="fas fa-chevron-up"
              style="color: #b7227e; font-size: 28px"
            ></i>
          </div>
        </div>
        <div class="col-md-3 border-right-desk px-4">
          <div class="pb-4 text-left">
            <h4>TIEMPO TOTAL DEL CURSO</h4>
            <p style="color: black">
              {{ getCourseDuration(getPublicCurrentCourseVideos) }}
            </p>
          </div>

          <div class="pb-2 text-left">
            <h4>CLASES</h4>
            <p style="color: black">
              {{ getPublicCurrentCourseVideos.length }} clases
            </p>
          </div>
        </div>
        <div class="col-md-3 px-4 text-left">
          <h4>ZONA ENFOCADA</h4>
          <p
            v-for="(category,key) in getPublicCurrentCourse.course_categories"
            :key="key"
            style="color: black"
          >
            {{ category.course_category.title }}
          </p>
          <!-- <p>Cejas</p>
        <p>Labios</p> -->
        </div>
      </div>
    </div>
    <div
      class="col-12 padding-mobile-container px-0 section-courses-payments py-4"
      style="background: #b5006f"
    >
      <div class="container-fluid size">
        <div class="text-left">
          <h1>PROGRAMA DEL CURSO</h1>
        </div>
        <div v-if="getPublicCurrentCourseVideos.length">
          <div
            v-for="(courseVideo, index) in getPublicCurrentCourseVideos"
            :key="index"
            class="container-fluid d-flex py-4 border-bottom px-0"
          >
            <div class="col-1 pl-0">
              <h1>{{ index + 1 }}</h1>
            </div>
            <div class="col-md-11 text-left">
              <h1 class="text-uppercase">
                {{ courseVideo.title }}
              </h1>
              <h2>
                <span
                  class="p-course-content"
                  v-html="courseVideo.content"
                ></span>
                <span class="time-courses mx-0">{{
                  formatTime(courseVideo)
                }}</span>
              </h2>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="col-12 text-center my-4">
            <h1>No tiene clases registradas</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonCourseDetails from '../Components/skeletons/SkeletonCourseDetails';
import VideoPlayer from '../../components/VideoPlayer';
import 'video.js/dist/video-js.css';
import { FETCH_PUBLIC_COURSE } from '../../store/actions/public';
import config from '@/config/config';
import format from 'format-duration';
import { ADD_COURSE_TO_CART } from '../../store/actions/cart';
import {
  CREATE_COURSE_USER,
  FETCH_COURSE_USER,
} from '../../store/actions/course';
import SkeletonSquare from '../../components/Skeleton/components/SkeletonSquare';

export default {
  name: 'AdminCoursePreview',
  components: { VideoPlayer, SkeletonCourseDetails, SkeletonSquare },
  data() {
    return {
      isLoading: true,
      rutaFree: '',
      isError: null,
      videoOptions: {},
      isLoadingCourseUser: true,
      courseUser: undefined,
      videoDesktopId: 'hls-video-desktop',
    };
  },
  metaInfo() {
    const seoTitle = this.getPublicCurrentCourse && this.getPublicCurrentCourse.seo ? `${this.getPublicCurrentCourse.seo.title || ''}`: undefined;
    return {
      title:  seoTitle ?? 'Maquillate.com'
    }
  },
  computed: {
    ...mapGetters([
      'getPublicCurrentCourse',
      'getPublicCurrentCourseVideos',
      'isAuthenticated',
      'getCart',
      'getProfile',
      'getUserCurses',
      'getCurrentCourseUser',
    ]),
  },
  watch: {
    getPublicCurrentCourse: function (newValue) {
      console.log('newValue', newValue);
      if (newValue) {
        this.videoOptions = {
          autoplay: false,
          controls: true,

          poster: newValue.trailer_poster ? newValue.trailer_poster : '',
          fluid: true,
          aspectRatio: '16:9',
          hls: true,
          plugins: {
            airPlay: {
              addButtonToControlBar: true, // defaults to `true`
            },
          },
        };
        if (newValue.trailer) {
          this.videoOptions = {
            ...this.videoOptions,
            sources: [
              {
                src: newValue.trailer ? newValue.trailer.video.url : '',
                type: 'application/x-mpegURL',
              },
            ],
          };
        }
      } else {
        this.videoOptions = {};
      }
      this.isLoading = false;
    },
  },
  async created() {
    await this.fetchCourse();
    if (this.getProfile && this.getProfile.id) await this.fetchCourseUser();
  },
  methods: {
    async fetchCourse() {
      await this.$store.dispatch(FETCH_PUBLIC_COURSE, this.$route.params.id);
    },
    async fetchCourseUser() {
      const courseExistId = this.getPublicCurrentCourse.id;
      const courseExistSlugName = this.getPublicCurrentCourse.slug_name;
      if (courseExistId && courseExistSlugName) {
        this.$store
          .dispatch(FETCH_COURSE_USER, {
            course_id: this.$route.params.id,
            user_id: this.getProfile.id,
          })
          .then(() => {
            this.isLoadingCourseUser = false;
          })
          .catch(() => {
            this.isLoadingCourseUser = false;
          });
      } else {
        this.$router.push({ name: 'NotFound' });
      }
    },
    formatTime(courseVideo) {
      const duration = courseVideo.video ? courseVideo.video.duration : 0;

      return format(duration * 1000);
    },
    getCourseVideoThumbnail(courseVideo) {
      if (!courseVideo.video)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return courseVideo.video.thumbnail;
    },
    getInstructorImage(instructors = []) {
      if (!instructors.length)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return instructors[0].avatar
        ? instructors[0].avatar.url
        : `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
    },
    getCourseDuration(courseVideos = []) {
      const duration = courseVideos.reduce((acc, curr) => {
        const _duration = curr.video ? parseInt(curr.video.duration) : 0;
        return acc + _duration;
      }, 0);

      return format(duration * 1000);
    },
    getInstructors(instructors) {
      if (!instructors.length) return 'Sin instructores asignados';

      return instructors
        .map((instructor) => `${instructor.firstname} ${instructor.lastname}`)
        .join(' , ');
    },

    createUserCourse() {
      const course = this.getPublicCurrentCourse;
      return this.getUserCurses.find(
        (userCourse) => userCourse.id === course.id,
      );
    },

    takeCourse() {
      // Check if user already take it
      const checkUserCourse = this.createUserCourse();
      const course = this.getPublicCurrentCourse;
      const courseVideos = this.getPublicCurrentCourseVideos;

      if (checkUserCourse) {
        return this.$router.push(
          `/curso/${course.id}/${course.slug_name}/video/1`,
        );
      }

      this.$store
        .dispatch(CREATE_COURSE_USER, {
          user_id: this.getProfile.id,
          course_id: this.getPublicCurrentCourse.id,
        })
        .then(() => {
          const firstCourseVideoId = courseVideos.length
            ? courseVideos[0].id
            : undefined;

          window.location = `${window.location.origin}/curso/${course.id}/${course.slug_name}/video/${firstCourseVideoId}`;
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para acceder al curso'),
        );
    },
    takeCourseWithOutLogin() {
      return this.$router.push(`/login?redirect_url=${window.location.href}`);
    },
    buyCourseWithOutLogin() {
      if (!this.checkCourseInCart()) {
        const course = this.getPublicCurrentCourse;
        this.$store.dispatch(ADD_COURSE_TO_CART, course).then(() => {
          this.notify('success', 'Curso agregado al carrito');
        });
      } else {
        this.notify('danger', 'Este curso ya lo tienes en el carrito');
      }
    },
    checkCourseInCart() {
      const course = this.getPublicCurrentCourse;
      const cart = this.getCart;
      return cart.find((item) => item.id === course.id);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style>
.text-left .show-courses__text:hover {
  text-decoration: none !important;
}

.show-in-mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .hide-in-mobile {
    display: none;
  }

  .show-in-mobile {
    display: block;
  }
}

.text-description-course {
  padding-right: 0px !important;
}

.bg-course {
  background-color: #fff !important;
}

.orientation-btn-mobile {
  padding-right: 0px !important;
}

.course-poster {
  object-position: top;
  object-fit: cover;
  max-height: 500px;
  width: 100%;
  height: auto;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75) 70%);
}

.p-course-content p strong,
.p-course-content span {
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
}
.section-courses-payments h2 .time-courses {
  font-size: 14px;
  font-weight: 500;
}

.text-white p {
  color: white;
}

.text-white li {
  font-size: 14px;
  padding-bottom: 10px;
}

.instructor-info__info-front-page {
  height: 100% !important;
}

.img-cover-instructor {
  height: 100% !important;
  max-height: 100% !important;
}

.img-course-background-image {
  height: 100% !important;
  max-height: 500px !important;
  object-fit: cover;
}

.instructor-info {
    background: white !important;
}


.img-course-cover-profile {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(70%, rgba(0, 0, 0, 0.75))
  )  !important;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75) 70%);
  width: 100%;
  position: absolute;
  height: 100%;
  max-height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

</style>
