<template>
  <skeleton-instructor-details v-if="isLoading" />
  <div
    v-else
    class="view-home__part7 instructor-info d-block pl-0 pr-0 instructor-size"
  >
    <div>
      <div class="col-12 px-0">
        <div class="banner-img-instructor">
          <div class="img-cover-instructor">
            <img
              :src="
                getPublicCurrentInstructor.poster
                  ? getPublicCurrentInstructor.poster.url
                  : '@/assets/images/logo/placeholder.png'
              "
              class="w-100"
              style="background: #f7f7f7"
            />
          </div>
          <div class="img-instructor-cover-profile">
            <h1>
              {{
                `${getPublicCurrentInstructor.firstname} ${getPublicCurrentInstructor.lastname}`
              }}
            </h1>
            <div
              class="instructor-info__info-front-page__info-social-medial d-flex pb-5"
            >
              <div v-if="getPublicCurrentInstructor.instagram">
                <a
                  :href="getPublicCurrentInstructor.instagram"
                  class="color-link-socialM"
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div v-if="getPublicCurrentInstructor.facebook">
                <a
                  :href="getPublicCurrentInstructor.facebook"
                  class="color-link-socialM"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
              </div>
              <div v-if="getPublicCurrentInstructor.whatsapp">
                <a
                  :href="`https://api.whatsapp.com/send?phone=${getPublicCurrentInstructor.whatsapp}&text=Hola,%20te%20escribo%20de%20Maquillate.com.%20Me%20interesa%20tus%20servicios.%20`"
                  class="color-link-socialM"
                  target="_blank"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
              <div v-if="getPublicCurrentInstructor.website">
                <a
                  :href="getPublicCurrentInstructor.website"
                  class="color-link-socialM"
                  target="_blank"
                >
                  <i class="fas fa-globe"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img
            :src="
              getPublicCurrentInstructor.avatar
                ? getPublicCurrentInstructor.avatar.url
                : 'https://www.superprof.es/imagenes/anuncios/profesor-home-esteticista-clases-diferentes-tipos-maquillaje-auto-maquillaje.jpg'
            "
            alt=""
            class="img-instructor-image-profile"
            style="background: #f7f7f7"
          />
        </div>
      </div>
      <div class="container-fluid size">
        <div class="row justify-content-end m-0">
          <div class="col-12 instructor-info__description">
            <p style="white-space: break-spaces">
              {{ getPublicCurrentInstructor.bio }}
            </p>
          </div>
        </div>
      </div>
      <div class="instructor-info__classes">
        <div class="container-fluid size mt-6">
          <h1>CLASES DE {{ getPublicCurrentInstructor.firstname }}</h1>

          <template v-if="isLoadingClass">
            <div class="row">
              <div v-for="index in 4" :key="index" class="col-sm-3">
                <skeleton-card-video />
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="classes.length" class="row">
              <card-class
                v-for="_class in classes"
                :key="_class.id"
                :data="_class"
                class="col-lg-3 col-md-6 col-12 p-2"
              />
              <div v-if="totalClassPages > classPage" class="col-sm-12 pt-4">
                <base-button
                  class="btn-maquillate p-2 text-unlock-button"
                  :loading="loadMoreClasses"
                  @click="loadMorePublicClass"
                >
                  Cargar mas clases
                </base-button>
              </div>
            </div>
            <div v-else class="my-4">
              <h3 style="text-align: center">No hay información disponible</h3>
            </div>
          </template>
        </div>
      </div>
      <div class="instructor-info__courses">
        <div class="container-fluid size mt-4">
          <h1>CURSOS DE {{ getPublicCurrentInstructor.firstname }}</h1>
          <template v-if="isLoadingCourses">
            <div class="row">
              <div v-for="index in 4" :key="index" class="col-sm-3">
                <skeleton-card-video />
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="courses.length" class="row">
              <card-course
                v-for="course in courses"
                :key="course.id"
                :data="course"
                class="col-lg-3 col-md-6 col-12 p-2"
              />
              <div v-if="totalCoursePages > coursePage" class="col-sm-12 pt-4">
                <base-button
                  class="btn-maquillate p-2 text-unlock-button"
                  :loading="loadMoreCourses"
                  @click="loadMorePublicCourses"
                >
                  Cargar mas cursos
                </base-button>
              </div>
            </div>
            <div v-else class="my-4">
              <h3 style="text-align: center">No hay información disponible</h3>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_PUBLIC_CLASSES,
  FETCH_PUBLIC_COURSES,
  FETCH_PUBLIC_INSTRUCTOR,
} from '../../store/actions/public';
import { mapGetters } from 'vuex';
import SkeletonInstructorDetails from '../Components/skeletons/SkeletonInstructorDetails';
import CardCourse from './Courses/components/CardCourse';
import SkeletonCardVideo from '../Components/skeletons/SkeletonCardVideo.vue';
import CardClass from './Courses/components/CardClass.vue';
import BaseButton from '../../components/BaseButton.vue';

export default {
  components: {
    SkeletonCardVideo,
    SkeletonInstructorDetails,
    CardCourse,
    CardClass,
    BaseButton,
  },
  data() {
    return {
      isLoading: true,
      isLoadingClass: true,
      isLoadingCourses: true,
      classPage: 1,
      coursePage: 1,
      loadMoreClasses: false,
      loadMoreCourses: false,
      courses: [],
      classes: [],
      perPage: 20,
    };
  },
  metaInfo() {
    const instructor = this.getPublicCurrentInstructor ? `${this.getPublicCurrentInstructor.firstname || ''} ${this.getPublicCurrentInstructor.lastname || ''}`: undefined;
    return {
      title:  instructor ? `${instructor} - Maquillate.com` :'Explorar Clases - Maquillate.com'
    }
  },
  computed: {
    ...mapGetters([
      'getPublicCurrentInstructor',
      'getPublicClasses',
      'getPublicCourses',
    ]),
    totalCoursePages() {
      return Math.ceil(this.getPublicCourses.total / this.perPage);
    },
    totalClassPages() {
      return Math.ceil(this.getPublicClasses.total / this.perPage);
    },
  },
  watch: {
    $route: async function (newValue) {
      const id = newValue.params.id;
      if (id) {
        this.isLoading = true;
        this.isLoadingClass = true;
        this.isLoadingCourses = true;

        await this.fetchInstructor();
        await this.fetchClassVideos();
        await this.fetchCourses();
      }
    },
    getPublicClasses: function (newValues) {
      this.classes = [...newValues.items];
    },
    getPublicCourses: function (newValues) {
      this.courses = [...newValues.items];
    },
  },
  async mounted() {
    await this.fetchInstructor();
    await this.fetchClassVideos();
    await this.fetchCourses();
  },
  methods: {
    async fetchInstructor() {
      await this.$store.dispatch(
        FETCH_PUBLIC_INSTRUCTOR,
        this.$route.params.id,
      );
      this.isLoading = false;
    },
    async fetchClassVideos(
      filter = {
        per_page: this.perPage,
        order_by: 'DESC',
        instructor_id: this.$route.params.id,
        page: this.classPage,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_CLASSES, filter)
        .then(() => {
          this.isLoadingClass = false;
          this.loadMoreClasses = false;
        })
        .catch(() => {
          this.isLoadingClass = false;
          this.loadMoreClasses = false;
          this.notify('danger', 'Tenemos problemas para cargar los clases');
        });
    },
    async fetchCourses(
      filter = {
        per_page: this.perPage,
        order_by: 'DESC',
        instructor_id: this.$route.params.id,
        page: this.coursePage,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_COURSES, filter)
        .then(() => {
          this.isLoadingCourses = false;
          this.loadMoreCourses = false;
        })
        .catch(() => {
          this.isLoadingCourses = false;
          this.loadMoreCourses = false;
          this.notify('danger', 'Tenemos problemas para cargar los cursos');
        });
    },

    async loadMorePublicClass() {
      const page = this.classPage + 1;
      this.classPage++;
      this.loadMoreCourses = true;
      await this.fetchClassVideos({
        page,
        order_by: 'DESC',
        instructor_id: this.$route.params.id,
        per_page: this.perPage,
      });
    },

    async loadMorePublicCourses() {
      const page = this.coursePage + 1;
      this.coursePage++;
      this.loadMoreCourses = true;
      await this.fetchCourses({
        page,
        order_by: 'DESC',
        instructor_id: this.$route.params.id,
        per_page: this.perPage,
      });
    },
  },
};
</script>

<style>
.instructor-info__classes h1 {
  font-size: 24px;
}

.instructor-info__courses h1 {
  font-size: 24px;
}
.instructor-info__description {
  padding: 30px 0px 10px 300px !important;
}

.img-cover-instructor,
.img-cover-instructor img {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.banner-img-instructor {
  height: auto;
}

.img-instructor-image-profile {
  border-radius: 50%;
  border: 7px solid white;
  width: 230px;
  height: 230px !important;
  max-height: 230px;
  object-fit: cover;
  margin-top: -100px;
  position: absolute;
  left: 10%;
}

.img-instructor-cover-profile {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(70%, rgba(0, 0, 0, 0.75))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75) 70%);
  width: 100%;
  position: absolute;
  height: 100%;
  max-height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.img-instructor-cover-profile h1 {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 980px) {
  .instructor-info__description p {
    font-size: 18px;
    text-align: center !important;
  }
  .instructor-info.instructor-size {
    padding: 50px 0px 0px 0px !important;
  }

  .instructor-info__description {
    padding: 200px 0px 10px 0px !important;
    align-content: center;
  }

  .img-instructor-cover-profile {
    height: 200px;
    max-height: 200px;
  }
  .img-cover-instructor {
    height: 200px;
    max-height: 200px;
  }
  .img-cover-instructor img {
    height: 200px;
    max-height: 200px;
  }

  .img-instructor-cover-profile h1 {
    font-size: 2rem;
  }
  .banner-img-instructor {
    height: 200px;
  }

  .img-cover-instructor {
    justify-content: center;
    display: flex;
  }
  .img-instructor-image-profile {
    border-radius: 50%;
    border: 7px solid white;
    width: 230px;
    height: 230px !important;
    max-height: 230px;
    object-fit: cover;
    margin-top: -40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
