var render = function render(){var _vm=this,_c=_vm._self._c;return _c('skeleton-loading',[_c('div',{staticClass:"view-home__part7 instructor-info d-block pl-0 pr-0 instructor-size"},[_c('div',[_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"img-cover-instructor"},[_c('img',{staticClass:"w-100 instructor-info__front-page",staticStyle:{"background":"#f7f7f7"},attrs:{"src":require("@/assets/images/logo/placeholder.png")}})]),_c('div',{staticClass:"instructor-info__info-front-page pb-6"},[_c('div',{staticClass:"skeleton-instructor-text"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '50px',
              width:'500px',
              marginBottom: '15px'
              },"count":1}})],1),_c('div',{staticClass:"instructor-info__info-front-page__info-social-medial d-flex mt-4"},[_c('div',{staticClass:"mr-2"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '25px',
              width:'25px'
              },"count":1}})],1),_c('div',{staticClass:"mr-2"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '25px',
              width:'25px'
              },"count":1}})],1),_c('div',{staticClass:"mr-2"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '25px',
              width:'25px'
              },"count":1}})],1),_c('div',[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '25px',
              width:'25px'
              },"count":1}})],1)])]),_c('div',[_c('img',{staticClass:"instructor-info__img-instructor",staticStyle:{"background":"#f7f7f7"},attrs:{"alt":"","src":require("@/assets/images/logo/profile_default.png")}})])]),_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"row justify-content-end m-0"},[_c('div',{staticClass:"col-12 instructor-info__description"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '20px',
              width:'100%',
              bottom: '20px'
              },"count":5}})],1)])]),_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"skeleton-instructor-text"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '40px',
              width:'400px',
              textAlign:'-webkit-center',
              },"count":1}})],1),_c('div',{staticClass:"row mt-4"},_vm._l((4),function(index){return _c('skeleton-card-video',{key:index,staticClass:"col-sm-3"})}),1)]),_c('div',{staticClass:"container-fluid size mt-4"},[_c('div',{staticClass:"skeleton-instructor-text"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '40px',
              width:'400px',
              textAlign:'-webkit-center',
              },"count":1}})],1),_c('div',{staticClass:"row mt-4"},_vm._l((4),function(index){return _c('skeleton-card-video',{key:index,staticClass:"col-sm-3"})}),1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }