<template>
  <div>
    <div class="page-container md-layout-column">
      <div class="container-fluid px-0 view-home-maquillate">
        <nav-bar-normal
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :show-navbar="this.showNavbar"
          :show-navigation="this.showNavigation"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-normal>
        <nav-bar-mobile
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :show-navbar="this.showNavbar"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-mobile>
      </div>
      <div class="view-home__part7 allClasses view-explore-classes">
        <div class="container-fluid size pr-0">
          <div class="row">
            <div
              class="col pr-0 mr-4 col-menubar margin-top-sidebar-explore padding-top-explore"
            >
              <ul class="allClasses__menubar">
                <router-link to="/explorar">
                  <li
                    :class="{
                      active: this.$route.name === 'ExploreView',
                    }"
                    class="text-uppercase"
                  >
                    Inicio
                  </li>
                </router-link>
                <router-link :to="allClassUrl">
                  <li
                    :class="{
                      active: this.$route.name === 'ClassNewView',
                    }"
                    class="text-uppercase"
                  >
                    Todas las Clases
                  </li>
                </router-link>
                <router-link :to="allCourseUrl">
                  <li
                    :class="{
                      active: this.$route.name === 'CoursesView',
                    }"
                    class="text-uppercase"
                  >
                    Todos los cursos
                  </li>
                </router-link>
                <template v-if="this.$route.name !== 'ExploreSearch'">
                  <li class="title-classes">Categorías</li>
                  <template v-if="getIsLoadingCategories">
                    <skeleton-loading>
                      <skeleton-square
                        :box-properties="{
                          height: '18px',
                          width: '100%',
                          bottom: '15px',
                        }"
                        :count="4"
                      ></skeleton-square>
                    </skeleton-loading>
                  </template>
                  <template v-else>
                    <router-link
                      v-for="category in getPublicCategories"
                      :key="category.id + category.title"
                      :to="`${categoryUrl}/${category.slug}`"
                    >
                      <li
                        :class="{
                          active:
                            currentPath === `${categoryUrl}/${category.slug}`,
                        }"
                      >
                        {{ category.title }}
                      </li>
                    </router-link>
                  </template>
                </template>
              </ul>
            </div>
            <div class="col content-allClasses container-course">
              <div class="tabs-section-explore-class">
                <div>
                  <announcements />
                </div>
                <div
                  class="col-12 mt-4 view-home-tabs pl-1 view-tabs-mobile mb-4 sidebar-classes sidebar-hand-left tabs-explore-section"
                >
                  <md-tabs class="md-transparent pl-0" md-alignment="fixed">
                    <md-tab
                      id="tab-all-categories"
                      :to="allCourseUrl"
                      md-label="Todas las categorias"
                    ></md-tab>
                    <md-tab
                      v-for="category in this.getPublicCategories"
                      :id="category.title"
                      :key="category.id"
                      :to="`${categoryUrl}/${category.slug}`"
                      :md-label="category.title"
                    >
                    </md-tab>
                  </md-tabs>
                </div>
              </div>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>

      <div class="view-home__part7 footer-mobile pt-5">
        <footer-home></footer-home>
      </div>
      <div class="view-home__part7 footer-desk pt-5">
        <footer-in-show-course></footer-in-show-course>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonSquare from '../../components/Skeleton/components/SkeletonSquare';
import SkeletonLoading from '../../components/Skeleton/SkeletonLoading';
import NavBarMobile from './components/header/navBarMobile.vue';
import NavBarNormal from './components/header/navBarNormal.vue';
import FooterHome from './components/footer/FooterHome.vue';
import FooterInShowCourse from './components/footer/FooterInShowCourse.vue';
import {
  CLASS_FEATURED_VIEW,
  CLASS_NEW_VIEW,
  CLASS_POPULAR_VIEW,
  CLASS_TREND_VIEW,
  COURSES_VIEW,
} from '../../util/constants';
import Announcements from '../Pages/Home/Components/DescubreComponents/Explore/Announcements';
import { USER_CLASS_FAVORITE } from '../../store/actions/user';

export default {
  name: 'ClassLayout',
  components: {
    SkeletonSquare,
    SkeletonLoading,
    NavBarMobile,
    NavBarNormal,
    FooterHome,
    FooterInShowCourse,
    Announcements,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      showExplore: false,
      showByCategory: false,
      coursesCategory: null,
      bgcolor: 'background:#000;',
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      showMenu: false,
      inputSearch: false,
      showCategories: false,
      showNavigation: false,
      showSearchMobile: false,
      bgColor: true,
      currentCategory: undefined,
      currentPath: '',
      categoryUrl: '/explorar/categoria',
      allClassUrl: '/explorar/clases',
      allCourseUrl: '/explorar/cursos',
      ExploreUrl: '/explorar',
    };
  },

  computed: {
    ...mapGetters([
      'getPublicCategories',
      'getIsLoadingCategories',
      'getProfile',
      'getPublicCategories',
      'getPublicCourseVideos',
      'isAuthenticated',
    ]),
  },

  watch: {
    $route: async function (to) {
      this.currentCategory = to.params.title;

      const name = to.name;
      if (name === CLASS_FEATURED_VIEW) {
        this.categoryUrl = '/explorar/destacados/categoria';
        this.allClassUrl = '/explorar/destacados';
      } else if (name === CLASS_POPULAR_VIEW) {
        this.categoryUrl = '/explorar/populares/categoria';
        this.allClassUrl = '/explorar/populares';
      } else if (name === COURSES_VIEW) {
        this.categoryUrl = '/explorar/cursos/categoria';
      } else if (name === CLASS_TREND_VIEW) {
        this.categoryUrl = '/explorar/populares/categoria';
        this.allClassUrl = '/explorar/populares';
      } else if (name === CLASS_NEW_VIEW) {
        this.categoryUrl = '/explorar/clases/categoria';
        this.allClassUrl = '/explorar/clases';
      } else {
        this.categoryUrl = '/explorar/categoria';
        this.allClassUrl = '/explorar/clases';
      }

      this.currentPath = this.$route.fullPath;
    },
  },
  async mounted() {
    this.currentCategory = this.$route.params.title;
    const name = this.$route.name;
    this.currentPath = this.$route.path;
    if (name === CLASS_FEATURED_VIEW) {
      this.categoryUrl = '/explorar/destacados/categoria';
      this.allClassUrl = '/explorar/destacados';
    } else if (name === CLASS_POPULAR_VIEW) {
      this.categoryUrl = '/explorar/populares/categoria';
      this.allClassUrl = '/explorar/populares';
    } else if (name === COURSES_VIEW) {
      this.categoryUrl = '/explorar/cursos/categoria';
    } else if (name === CLASS_TREND_VIEW) {
      this.categoryUrl = '/explorar/populares/categoria';
      this.allClassUrl = '/explorar/populares';
    } else if (name === CLASS_NEW_VIEW) {
      this.categoryUrl = '/explorar/clases/categoria';
      this.allClassUrl = '/explorar/clases';
    } else {
      this.categoryUrl = '/explorar/categoria';
      this.allClassUrl = '/explorar/clases';
    }
  },
  methods: {
    async fetchClasses() {
      this.$store
        .dispatch(USER_CLASS_FAVORITE, {
          is_favorite: 1,
          page: this.page,
          per_page: 100,
        })
        .then(() => (this.isLoading = false));
    },
  },
};
</script>
<style lang="scss">
.container-course {
  min-height: 100vh;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background-color: #b7227e;
}

.view-home-tabs .md-tabs.md-alignment-fixed .md-tabs-navigation .md-button {
  background: #b7227e;
  border-radius: 6px;
  min-width: fit-content !important;
}
.md-tabs.md-alignment-fixed .md-tabs-navigation
{
  margin-right: 40px;
}

.md-tabs-navigation {
  padding-right: 20px;
}

.view-home__part7 {
  padding: 0px;
}

@media (max-width: 576px) {
  .view-home__part7 {
    padding: 0px;
  }
  .view-explore-classes {
    margin-top: 4rem !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .view-explore-classes {
    margin-top: 5rem !important;
  }
}

@media (min-width: 992px) {
  .sidebar-classes {
    display: none;
  }

  .VueCarousel.carousel-allClasses {
    margin-bottom: 2rem !important;
  }
  .view-explore-classes {
    padding-top: 6rem;
  }
}

.sidebar-hand-left .md-tabs-navigation {
  left: 50px;
}

.sidebar-hand-left::after {
  content: '';
  background-image: url('../../../src/assets/images/logo/swipe-left.gif');
  width: 40px;
  height: 40px;
  background-size: contain;
  position: absolute;
  top: 0;
  transform: translateY(-10%);
  left: 5px;
}

.md-ripple {
  z-index: 0 !important;
}

@media (max-width: 576px) {
  .tabs-section-explore-class {
    margin-top: 1rem !important;
  }
}

.view-home-tabs .md-tabs.md-theme-default .md-tabs-navigation .md-button
{
  color: #fff !important
}
.view-home-tabs .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active
{
  background-color: #000 !important;
}
</style>
