<template>
  <div
    class="view-home__part7 instructor-info d-block pl-0 pr-0 instructor-size"
  >
    <div v-if="PartnerInHome && ParterClass">
      <div class="col-12 px-0">
        <img
          class="w-100 instructor-info__front-page"
          :src="
            PartnerInHome.thumbnail_header
              ? PartnerInHome.thumbnail_header
              : 'https://tenimage.es/wp-content/uploads/2016/01/eyeliner.jpg'
          "
        />
        <div class="instructor-info__info-front-page pb-6">
          <h1>{{ PartnerInHome.name }}</h1>
          <!--
          <div class="instructor-info__info-front-page__info-social-medial">
            <i class="fab fa-facebook-f"></i>
            <i class="fab fa-instagram"></i>
          </div>
          -->
        </div>
        <div>
          <img
            class="instructor-info__img-instructor"
            :src="
              PartnerInHome.avatar
                ? PartnerInHome.avatar
                : 'https://www.superprof.es/imagenes/anuncios/profesor-home-esteticista-clases-diferentes-tipos-maquillaje-auto-maquillaje.jpg'
            "
            alt=""
          />
        </div>
      </div>
      <div class="container-fluid size">
        <div
          class="row justify-content-end m-0"
          style="border-bottom: 3px solid #f1f1f1"
        >
          <div class="col-12 instructor-info__description">
            <p>
              <!-- {{PartnerInHome.bio == 'null' ? '' : PartnerInHome.bio}} -->
            </p>
          </div>
        </div>
        <div
          class="instructor-info__classes"
          style="background-color: rgb(252, 252, 252)"
        >
          <div class="container-fluid size">
            <h1 class="pt-1">CLASES DE {{ PartnerInHome.name }}</h1>
            <div>
              <div
                v-if="ParterClass.length >= 1"
                class="instructor-info__classes-instructor"
              >
                <div
                  v-for="Lesson in ParterClass"
                  :key="Lesson.id"
                  class="classes-card"
                >
                  <div class="img-info">
                    <a
                      :href="
                        '/' +
                        Lesson.course_id +
                        '/' +
                        Lesson.name_course.slug_name +
                        '/lesson/' +
                        Lesson.id +
                        '/' +
                        Lesson.media.slug_title
                      "
                    >
                      <img
                        class="img"
                        :src="Lesson.thumbnail_url"
                        alt=""
                        style="
                          object-fit: cover;
                          width: 100%;
                          max-height: 100% !important;
                        "
                      />
                      <div class="time-img">
                        <span>{{ Lesson.total_time }}</span>
                      </div>
                    </a>
                  </div>
                  <div class="info">
                    <a
                      style="text-decoration: none"
                      :href="
                        '/' +
                        Lesson.course_id +
                        '/' +
                        Lesson.name_course.slug_name +
                        '/lesson/' +
                        Lesson.id +
                        '/' +
                        Lesson.media.slug_title
                      "
                    >
                      <p class="mb-1">{{ Lesson.media.title }}</p>
                    </a>
                    <a
                      style="text-decoration: none"
                      :href="
                        '/' +
                        Lesson.course_id +
                        '/' +
                        Lesson.name_course.slug_name +
                        '/lesson/' +
                        0 +
                        '/' +
                        Lesson.media.slug_title
                      "
                    >
                      <p class="mb-1">{{ Lesson.name_course.name }}</p>
                    </a>
                    <p>{{ Lesson.media.content }}</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <h3 style="text-align: center">
                  No hay información disponible
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 3000px; padding-block: 300px">
      <loading-panel></loading-panel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  INSTRUCTOR_GET_INSTRUCTOR_IN_HOME,
  INSTRUCTOR_GET_ALL_CLASS_IN_HOME,
} from '@/store/actions/instructor';
import LoadingPanel from '../../../../components/LoadingPanel.vue';
import axios from 'axios';
import config from '@/config/config';

export default {
  components: { LoadingPanel },
  data() {
    return {
      instructor_id: null,
      instructor: null,
      PartnerInHome: null,
      ParterClass: null,
    };
  },
  mounted() {
    this.getDetailsPartner();
    this.getClassPartner();
  },
  methods: {
    async getDetailsPartner() {
      // this.$store
      //     .dispatch(INSTRUCTOR_GET_INSTRUCTOR_IN_HOME, this.$route.params.id)
      //     .then((resp) => {
      //       // this.instructor = this.getPartnerInHome
      //       // console.log(this.instructor)
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      axios
        .get(config.data.apiUrl + '/partner/' + this.$route.params.id)
        .then((resp) => {
          console.log(resp.data, 'partner 1');
          this.PartnerInHome = resp.data;
        })
        .catch((err) => {
          console.log(err, 'sin data panther 1 :,v');
        });
    },
    async getClassPartner() {
      // this.$store
      //     .dispatch(INSTRUCTOR_GET_ALL_CLASS_IN_HOME, this.$route.params.id)
      //     .then((resp) => {
      //       // this.instructor = this.getPartnerInHome
      //       // console.log(this.instructor)
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      axios
        .get(
          config.data.apiUrl + '/partner/' + this.$route.params.id + '/class',
        )
        .then((resp) => {
          console.log(resp.data, 'partner 2');
          this.ParterClass = resp.data;
        })
        .catch((err) => {
          cosole.log(err);
          console.log(err, 'sin data panther 2 :,v');
        });
    },
  },
  // computed: {
  //   ...mapGetters(["getPartnerInHome", "getAllParterClass"])
  // }
};
</script>

<style></style>
