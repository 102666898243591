<template>
  <div>
    <div>
      <img
        alt=""
        class="w-100 mb-2 instructor-info__front-page-descript"
        src="@/assets/images/logo/placeholder.png"
      />
    </div>
    <div class="container-fluid size pr-0">
      <h1 class="my-3 text-description-course skeleton-course-title">
        <skeleton-square
          :box-properties="{
            height: '22px',
            width: '600px',
          }"
          :count="1"
        />
      </h1>
      <div
        class="d-flex justify-content-center orientation-btn-mobile skeleton-course-title"
      >
        <skeleton-square
          :box-properties="{
            height: '45px',
            width: '200px',
          }"
          :count="1"
        />
      </div>
      <div class="my-5 tabs-description-course style-config">
        <div class="row justify-content-between mr-0 margin-mobile-container">
          <div class="col-md-5">
            <h1 class="mt-3">
              <skeleton-square
                :box-properties="{
                  height: '22px',
                  width: '200px',
                }"
                :count="1"
              />
            </h1>
            <p class="margin-top-description" style="color: black">
              <skeleton-square
                :box-properties="{
                  height: '18px',
                  width: '100%',
                  bottom: '15px',
                }"
                :count="4"
              />
            </p>
          </div>
          <div class="col-md-6 pr-0 pl-0 embed-container">
            <video-player
              ref="previewplayer"
              :options="videoOptions"
              class="videoplayer mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import VideoPlayer from '../../../components/VideoPlayer';
export default {
  name: 'SkeletonCourseDetails',
  components: {
    SkeletonSquare,
    VideoPlayer,
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        poster: require('@/assets/images/logo/placeholder.png'),
        fluid: true,
        aspectRatio: '16:9',
        hls: true,
        plugins: {
          airPlay: {
            addButtonToControlBar: true, // defaults to `true`
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.skeleton-course-title {
  text-align: -webkit-center;
}
</style>
