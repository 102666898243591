<template>
  <carousel
    :per-page="1"
    :speed="500"
    class="carousel-allClasses mb-2"
    navigation-next-label=""
    navigation-prev-label=""
  >
    <slide
      v-for="advertisement in getPublicAdvertisements"
      :key="advertisement.id"
      class="card-flex-basis"
    >
      <div class="card-announcement">
        <div
          class="col-12 col-lg-6 px-0 colorContainerMaquillate allClasses__container__publish-img"
        >
          <img
            v-if="advertisement.poster && advertisement.poster.url"
            :src="advertisement.poster.url"
            alt=""
            class="allClasses__img p-0"
          />
          <img
            v-else
            alt=""
            class="allClasses__img p-0"
            src="https://media.glamour.mx/photos/6190f9e1a6e030d648112cc5/master/w_1600%2Cc_limit/141714.jpg"
          />
        </div>
        <div class="col-lg-6 allClasses__container__info" style="width: 100%">
          <h1
            class="allClasses__title__subtitles"
            style="text-transform: uppercase"
          >
            {{ advertisement.title }}
          </h1>
          <h3
            class="allClasses__title__description"
            v-html="advertisement.content"
          ></h3>

          <a
            v-if="advertisement.text_button && advertisement.url_button"
            :href="advertisement.url_button"
            style="text-decoration: none"
          >
            <button class="btn button-callAction mt-3">
              {{ advertisement.text_button }}
            </button>
          </a>
        </div>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';
import { FETCH_PUBLIC_ADVERTISEMENT_LIST } from '../../../../../../store/actions/public';

export default {
  name: 'AnnouncementCourse',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getPublicAdvertisements']),
  },
  watch: {},
  mounted() {
    this.fetchAdvertisements();
  },

  methods: {
    fetchAdvertisements() {
      this.$store.dispatch(FETCH_PUBLIC_ADVERTISEMENT_LIST);
    },
  },
};
</script>

<style>
.carousel-allClasses {
  padding-top: 0px !important;
}
.colorContainerMaquillate {
  background: #000 !important;
}

.VueCarousel.carousel-allClasses .VueCarousel-pagination {
  padding-right: 35px;
}
.VueCarousel.carousel-allClasses .VueCarousel-wrapper {
  border-radius: 10px;
}

.button-callAction {
  width: 160px;
  font-size: 13px;
  padding: 10px;
}

.allClasses__img {
  border-radius: 0px 0px 0px 0px !important;
  object-fit: cover;
}

.allClasses__container__info {
  border-radius: 0px 0px 0px 0px !important;
}

.card-announcement {
  height: 300px;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 2px;
  padding-right: 2px;
}

@media screen and (max-width: 767px) {
  .allClasses__container__info {
    border-radius: 0px 0px 0px 0px !important;
    height: 200px;
    max-height: 400px;
    padding: 0px 40px;
  }

  .allClasses__img {
    width: 100%;
    height: auto;
    object-fit: fill;
    padding: 0px;
    background-color: #000;
  }

  .card-announcement {
    height: 400px;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 2px;
    padding-right: 2px;
  }

  .card-flex-basis {
    flex-basis: 100%;
    width: 100%;
    height: auto;
  }

  .VueCarousel-inner {
    margin-right: 0px;
    margin-left: 0px;
  }

  .allClasses__container__publish-img {
    height: 200px;
  }
}
</style>
