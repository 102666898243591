var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-fluid size pr-0"},[_c('h1',{staticClass:"my-3 text-description-course skeleton-course-title"},[_c('skeleton-square',{attrs:{"box-properties":{
          height: '22px',
          width: '600px',
        },"count":1}})],1),_c('div',{staticClass:"d-flex justify-content-center orientation-btn-mobile skeleton-course-title"},[_c('skeleton-square',{attrs:{"box-properties":{
          height: '45px',
          width: '200px',
        },"count":1}})],1),_c('div',{staticClass:"my-5 tabs-description-course style-config"},[_c('div',{staticClass:"row justify-content-between mr-0 margin-mobile-container"},[_c('div',{staticClass:"col-md-5"},[_c('h1',{staticClass:"mt-3"},[_c('skeleton-square',{attrs:{"box-properties":{
                height: '22px',
                width: '200px',
              },"count":1}})],1),_c('p',{staticClass:"margin-top-description",staticStyle:{"color":"black"}},[_c('skeleton-square',{attrs:{"box-properties":{
                height: '18px',
                width: '100%',
                bottom: '15px',
              },"count":4}})],1)]),_c('div',{staticClass:"col-md-6 pr-0 pl-0 embed-container"},[_c('video-player',{ref:"previewplayer",staticClass:"videoplayer mt-4",attrs:{"options":_vm.videoOptions}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"w-100 mb-2 instructor-info__front-page-descript",attrs:{"alt":"","src":require("@/assets/images/logo/placeholder.png")}})])
}]

export { render, staticRenderFns }