var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-container md-layout-column"},[_c('div',{staticClass:"container-fluid px-0 view-home-maquillate"},[_c('nav-bar-normal',{attrs:{"bg-color":this.bgColor,"get-profile":_vm.getProfile,"show-navbar":this.showNavbar,"show-navigation":this.showNavigation,"show-search-mobile":this.showSearchMobile}}),_c('nav-bar-mobile',{attrs:{"bg-color":this.bgColor,"get-profile":_vm.getProfile,"show-navbar":this.showNavbar,"show-search-mobile":this.showSearchMobile}})],1),_c('div',{staticClass:"view-home__part7 allClasses view-explore-classes"},[_c('div',{staticClass:"container-fluid size pr-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pr-0 mr-4 col-menubar margin-top-sidebar-explore padding-top-explore"},[_c('ul',{staticClass:"allClasses__menubar"},[_c('router-link',{attrs:{"to":"/explorar"}},[_c('li',{staticClass:"text-uppercase",class:{
                    active: this.$route.name === 'ExploreView',
                  }},[_vm._v(" Inicio ")])]),_c('router-link',{attrs:{"to":_vm.allClassUrl}},[_c('li',{staticClass:"text-uppercase",class:{
                    active: this.$route.name === 'ClassNewView',
                  }},[_vm._v(" Todas las Clases ")])]),_c('router-link',{attrs:{"to":_vm.allCourseUrl}},[_c('li',{staticClass:"text-uppercase",class:{
                    active: this.$route.name === 'CoursesView',
                  }},[_vm._v(" Todos los cursos ")])]),(this.$route.name !== 'ExploreSearch')?[_c('li',{staticClass:"title-classes"},[_vm._v("Categorías")]),(_vm.getIsLoadingCategories)?[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
                        height: '18px',
                        width: '100%',
                        bottom: '15px',
                      },"count":4}})],1)]:_vm._l((_vm.getPublicCategories),function(category){return _c('router-link',{key:category.id + category.title,attrs:{"to":`${_vm.categoryUrl}/${category.slug}`}},[_c('li',{class:{
                        active:
                          _vm.currentPath === `${_vm.categoryUrl}/${category.slug}`,
                      }},[_vm._v(" "+_vm._s(category.title)+" ")])])})]:_vm._e()],2)]),_c('div',{staticClass:"col content-allClasses container-course"},[_c('div',{staticClass:"tabs-section-explore-class"},[_c('div',[_c('announcements')],1),_c('div',{staticClass:"col-12 mt-4 view-home-tabs pl-1 view-tabs-mobile mb-4 sidebar-classes sidebar-hand-left tabs-explore-section"},[_c('md-tabs',{staticClass:"md-transparent pl-0",attrs:{"md-alignment":"fixed"}},[_c('md-tab',{attrs:{"id":"tab-all-categories","to":_vm.allCourseUrl,"md-label":"Todas las categorias"}}),_vm._l((this.getPublicCategories),function(category){return _c('md-tab',{key:category.id,attrs:{"id":category.title,"to":`${_vm.categoryUrl}/${category.slug}`,"md-label":category.title}})})],2)],1)]),_c('router-view')],1)])])]),_c('div',{staticClass:"view-home__part7 footer-mobile pt-5"},[_c('footer-home')],1),_c('div',{staticClass:"view-home__part7 footer-desk pt-5"},[_c('footer-in-show-course')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }